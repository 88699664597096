import React from 'react';
import TemplatePage1 from '@components/TemplatePage/TemplatePage';
import styled from 'styled-components';
import { FaGlobeAmericas } from 'react-icons/fa';
import adorno from '@images/objetivos/adorno.png';
import questn from "@images/questn-logo.png";
import logo from "@images/Logos/Logo1.png";

const IndexPage = () => {
  return (
    <TemplatePage1
      title={"¿What is QuEST LAC Network?"}
      description={"Here we will explain it"}
      adorno = {<Adorno/>}
      content={(
        
        
        <Wrapper>
          <TextWrapper>
            <p>
              It is the Latin American network for colaboration of <a href={"https://questnetwork.org/"} target={"_blank"} rel={"noreferrer"} className={"text-tema4 cursor-pointer font-bold"} >QuEST Global</a>, for the development of evidence of of quality for the transforamtion of the Health Systems of Latin America and The Caribbean.
            </p>
            <div className={"w-full flex sm:flex-row flex-col items-center sm:justify-around"}>
              <img className={"w-56 mt-4"} src={questn} alt={questn}/>
              <img className={"w-56 mt-4"} src={logo} alt={logo}/>
            </div>
            <SmallContainer>
            <span>
              <span>
                <FaGlobeAmericas />
              </span>
              <p>
              Research about the quality of the health systems.
              </p>
            </span>
            <span>
              <span>
                <FaGlobeAmericas />
              </span>
              <p>
              Promote sinergies in research and development of methods of evaluation the quality of health.</p>
            </span>
            <span>
              <span>
                <FaGlobeAmericas />
              </span>
              <p>
             Follow the recomendations of the Lancet Commission on Health Systems.
              </p>
            </span>
            <span>
              <span>
                <FaGlobeAmericas />
              </span>
              <p>
              Promote the empowering of regional groups in order to create solutions based on evidence for the decision makers.</p>
            </span>
            </SmallContainer>
          </TextWrapper>
        </Wrapper>
        
      )}
    />
  )
};

export default IndexPage

const Adorno = styled.div`
position: absolute;
top: 0;
left: 0;
height:110%;
@media only screen and (min-width:900px){
  width: 50%;
  background-position-x:-40%;
  background-image: url(${adorno});
background-size: cover;
background-repeat: no-repeat;
}
@media only screen and (min-width:1200px){
  width: 50%;
  background-position-x:0%;
  background-image: url(${adorno});
background-size: cover;
background-repeat: no-repeat;
}
`;

const TextWrapper = styled.div`
max-width: 900px;
float: right;
text-align: justify;
padding: auto 20px;
background-color: #FFFFFFDD;
padding: 10px;
`;

const SmallContainer = styled.div`
max-width:1000px;
width:90%;
float: right;
padding: auto 20px;
`;




const Wrapper = styled.div`

p{
  font-size: 21px;
  line-height: 24px;
}

span{
  display: flex;
	flex-direction: row;
	align-items: center;

  span{
    height:50px;
    min-width:50px;
    width:50px;
    padding: 0.2rem;
    margin: 0.5rem;
    border-bottom: none;
  }

  p{
    border-bottom: none;
    font-size: 21px;
    line-height: 24px;
    padding-bottom: 0rem;
    margin-top: 0rem;
    margin-bottom: 0rem;
  }



}
`

